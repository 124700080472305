<template>
  <div
    :class="`sidebar hidden md:block relative h-screen ${
      open ? 'w-[250px]' : 'w-[70px]'
    } duration-100`"
    :style="`background-color: ${homeConfig.menuColor};`"
  >
    <div
      class="w-full flex justify-center items-center pt-5 mb-4 h-[60px] min-h-[60px]"
    >
      <q-img
        v-show="!open"
        @click="changeStatus"
        :src="homeConfig.imageIconMenu"
        spinner-color="grey-5"
        spinner-size="2rem"
        fit="contain"
        style="width: 40px"
        class="cursor-pointer"
      />

      <div v-show="open" :class="`mr-3 cursor-pointer`">
        <q-img
          @click="changeStatus"
          :src="homeConfig.imageNameMenu"
          spinner-color="grey-5"
          spinner-size="30px"
          fit="contain"
          style="height: 100%; width: 175px; max-width: 175px"
        />
      </div>
      <q-btn
        @click="changeStatus"
        size="9px"
        round
        no-shadow
        light
        :icon="`${
          open ? 'fa-solid fa-chevron-left' : 'fa-solid fa-chevron-right'
        }`"
        class="text-white absolute top-10 left-4 transition-all duration-100"
        :class="{ 'ml-[220px]': open, 'ml-[40px]': !open }"
        :style="`background-color: ${homeConfig.actionColor}`"
      />
    </div>
    <!-- aqui é o menu fechado icons -->
    <div class="w-full h-[calc(100vh_-_60px)] flex flex-col pt-5 pb-2 relative">
      <MenuSkeletonComponentVue v-if="status" />
      <q-scroll-area class="w-full h-full" v-else>
        <q-list class="w-full">
          <template v-if="open">
            <q-item
              v-for="(item, index) in menu"
              @mouseenter="showMenu(item, index)"
              :key="index.id"
              class="text-grey300 flex justify-center p-0 pb-[10px]"
            >
              <q-expansion-item
                group="groupA"
                dense
                dense-toggle
                v-model="item.opened"
                :header-class="`text-white p-0 mx-4 ${
                  isMenuSelected(item)
                    ? `${
                        item.opened
                          ? 'bg-white/[.06] rounded-tr-[10px]'
                          : 'bg-white/[.1] rounded-[10px]'
                      } ml-[10px] border-l-[5px]`
                    : 'rounded-[10px]'
                }`"
                :header-style="{ borderColor: homeConfig.actionColor }"
                :expand-icon-class="open ? 'visible' : 'invisible'"
                :class="`rounded group w-full p-0 ${open ? '' : 'h-12'}`"
              >
                <template #header>
                  <div
                    class="relative p-0"
                    @mouseenter="calcPosOfBox(index)"
                    @mouseleave="menu_direction = true"
                  >
                    <!-- <q-item-section avatar> -->
                    <q-avatar
                      size="lg"
                      :icon="item.icon"
                      style="cursor: pointer"
                    ></q-avatar>
                    <!-- </q-item-section>  -->

                    <!-- MENU HOVER -->
                    <ul
                      ref="ref_menu"
                      id="SubmenuHover"
                      :class="`absolute hidden z-100 w-[13rem] bg-primary left-[45px] ${
                        menu_direction ? '-top-[10px]' : '-bottom-[15px]'
                      } z-10 shadow-2 rounded  ${!open && 'group-hover:block'}`"
                    >
                      <span
                        class="block py-[4px] px-2 pl-3 text-white font-bold uppercase text-xs"
                      >
                        {{
                          item.title.startsWith("$")
                            ? $t(item.title)
                            : item.title
                        }}
                      </span>
                      <div
                        :class="`MenuHoverScrool overflow-y-auto ${
                          !menu_direction ? 'max-h-[320px]' : 'max-h-full'
                        }`"
                      >
                        <router-link
                          v-for="(submenuItem, index) in item.submenu"
                          :key="index.id"
                          :to="`/${submenuItem.key}`"
                          class="block py-[4px] px-2 pl-5 text-white opacity-60 hover:opacity-100 font-medium text-[12px] lowercase"
                        >
                          {{
                            submenuItem.title.startsWith("$")
                              ? $t(submenuItem.title)
                              : submenuItem.title
                          }}
                        </router-link>
                      </div>
                    </ul>
                  </div>

                  <q-item-section
                    v-show="open"
                    class="pl-[10px] font-normal text-xs"
                  >
                    {{
                      item.title.startsWith("$") ? $t(item.title) : item.title
                    }}
                  </q-item-section>
                </template>

                <li
                  v-show="open"
                  v-for="(submenuItem, index) in item.submenu"
                  :key="index.id"
                  @click="this.$router.push(`/${submenuItem.key}`)"
                  :class="`
                    ${
                      isRouteSelected(submenuItem)
                        ? 'opcaity-100 text-bold'
                        : 'opacity-60'
                    }
                    ${
                      isMenuSelected(item) ? ' bg-white/[.1] ml-[10px] p-4' : ''
                    }
                    text-white
                    hover:opacity-100
                    transition
                    ease-in-out
                    delay-50
                    text-xs
                    flex
                    items-center
                    gap-x-4
                    cursor-pointer
                    p-2
                    mx-[16px]
                    duration-100
                    py-2
                    ${
                      index === item.submenu.length - 1
                        ? 'rounded-b-[10px]'
                        : ''
                    }
                  `"
                >
                  <router-link
                    id="MenuOpenHoverText"
                    :to="`/${submenuItem.key}`"
                    :style="`color: ${
                      isRouteSelected(submenuItem)
                        ? `${homeConfig.actionColor}`
                        : ''
                    }`"
                    :class="`${
                      isRouteSelected(submenuItem)
                        ? `color:hover-[${homeConfig.actionColor}]`
                        : ''
                    }`"
                  >
                    {{
                      submenuItem.title.startsWith("$")
                        ? $t(submenuItem.title)
                        : submenuItem.title
                    }}
                  </router-link>
                </li>
              </q-expansion-item>
            </q-item>
          </template>

          <template v-else>
            <q-item
              v-for="(item, index) in menu"
              @mouseenter="showMenu(item, index)"
              :key="index.id"
              class="flex items-center justify-center p-0"
              :class="`${
                isMenuSelected(item) ? ' bg-white/[.1] border-l-[5px]' : ''
              }`"
              :style="`border-color: ${homeConfig.actionColor}`"
            >
              <q-avatar
                size="lg"
                :icon="item.icon"
                class="text-white"
                style="cursor: pointer"
              ></q-avatar>

              <q-menu
                v-model="item.show"
                @mouseleave="item.show = false"
                :offset="[-75, -50]"
              >
                <q-list
                  dense
                  :style="`background-color: ${homeConfig.menuColor}`"
                >
                  <q-item-label
                    header
                    class="block text-white font-black uppercase text-xs"
                    >{{
                      item.title.startsWith("$") ? $t(item.title) : item.title
                    }}</q-item-label
                  >
                  <q-item
                    v-for="(submenuItem, index) in item.submenu"
                    dense
                    :key="index.id"
                    class="p-0"
                  >
                    <router-link
                      :to="`/${submenuItem.key}`"
                      class="hover:opacity-100 text-[12px]"
                      :class="`${
                        isRouteSelected(submenuItem)
                          ? 'opacity-100 font-bold'
                          : 'opacity-60 font-medium'
                      }`"
                      :style="`color: ${
                        isRouteSelected(submenuItem)
                          ? `${homeConfig.actionColor}`
                          : 'white'
                      }`"
                    >
                      {{
                        submenuItem.title.startsWith("$")
                          ? $t(submenuItem.title)
                          : submenuItem.title
                      }}
                    </router-link>
                  </q-item>
                </q-list>
              </q-menu>
            </q-item>
          </template>
        </q-list>
        <!-- <div
            class="flex justify-center my-1"
          >
            <hr class="border-[0.px] text-grey400 w-[70%]" />
          </div> -->
        <q-list class="w-full">
          <template v-if="open">
            <q-item
              v-for="(item, index) in menuViews"
              @mouseenter="showMenu(item, index)"
              :key="index.id"
              class="text-grey300 flex justify-center p-0 pb-[10px]"
            >
              <q-expansion-item
                group="groupA"
                dense
                dense-toggle
                v-model="item.opened"
                :header-class="`text-white p-0 mx-4 ${
                  isMenuSelected(item)
                    ? `${
                        item.opened
                          ? 'bg-white/[.06] rounded-tr-[10px]'
                          : 'bg-white/[.1] rounded-[10px]'
                      } ml-[10px] border-l-[5px]`
                    : 'rounded-[10px]'
                }`"
                :header-style="{ borderColor: homeConfig.actionColor }"
                :expand-icon-class="open ? 'visible' : 'invisible'"
                :class="`rounded group w-full p-0 ${open ? '' : 'h-12'}`"
              >
                <template #header>
                  <div
                    class="relative p-0"
                    @mouseenter="calcPosOfBox(index)"
                    @mouseleave="menu_direction = true"
                  >
                    <!-- <q-item-section avatar> -->
                    <q-avatar
                      size="lg"
                      :icon="item.icon"
                      style="cursor: pointer"
                    ></q-avatar>
                    <!-- </q-item-section>  -->

                    <!-- MENU HOVER -->
                    <ul
                      ref="ref_menu"
                      id="SubmenuHover"
                      :class="`absolute hidden z-100 w-[13rem] bg-grey900 left-[45px] ${
                        menu_direction ? '-top-[10px]' : '-bottom-[15px]'
                      } z-10 shadow-2 rounded  ${!open && 'group-hover:block'}`"
                    >
                      <span
                        class="block py-[4px] px-2 pl-3 text-white font-bold uppercase text-xs"
                      >
                        {{
                          item.title.startsWith("$")
                            ? $t(item.title)
                            : item.title
                        }}
                      </span>
                      <div
                        :class="`MenuHoverScrool overflow-y-auto ${
                          !menu_direction ? 'max-h-[320px]' : 'max-h-full'
                        }`"
                      >
                        <router-link
                          v-for="(submenuItem, index) in item.submenu"
                          :key="index.id"
                          :to="`/${submenuItem.key}`"
                          class="block py-[4px] px-2 pl-5 lowercase text-white opacity-60 hover:opacity-100 font-medium text-[12px]"
                        >
                          {{
                            submenuItem.title.startsWith("$")
                              ? $t(submenuItem.title)
                              : submenuItem.title
                          }}
                        </router-link>
                      </div>
                    </ul>
                  </div>

                  <q-item-section
                    v-show="open"
                    class="pl-[10px] font-normal text-xs"
                  >
                    {{
                      item.title.startsWith("$") ? $t(item.title) : item.title
                    }}
                  </q-item-section>
                </template>

                <li
                  v-show="open"
                  v-for="(submenuItem, index) in item.submenu"
                  :key="index.id"
                  @click="this.$router.push(`/${submenuItem.key}`)"
                  :class="`
                    ${
                      isRouteSelected(submenuItem)
                        ? 'opcaity-100 text-bold'
                        : 'opacity-60'
                    }
                    ${
                      isMenuSelected(item) ? ' bg-white/[.1] ml-[10px] p-4' : ''
                    }
                    text-white
                    hover:opacity-100
                    transition
                    ease-in-out
                    delay-50
                    text-xs
                    flex
                    items-center
                    gap-x-4
                    cursor-pointer
                    p-2
                    mx-[16px]
                    duration-100
                    py-2
                    ${
                      index === item.submenu.length - 1
                        ? 'rounded-b-[10px]'
                        : ''
                    }
                  `"
                >
                  <router-link
                    id="MenuOpenHoverText"
                    :to="`/${submenuItem.key}`"
                    :style="`color: ${
                      isRouteSelected(submenuItem)
                        ? `${homeConfig.actionColor}`
                        : ''
                    }`"
                  >
                    {{
                      submenuItem.title.startsWith("$")
                        ? $t(submenuItem.title)
                        : submenuItem.title
                    }}
                  </router-link>
                </li>
              </q-expansion-item>
            </q-item>
          </template>
          <template v-else>
            <q-item
              v-for="(item, index) in menuViews"
              @mouseenter="showMenu(item, index)"
              :key="index.id"
              class="flex items-center justify-center p-0"
              :class="`${
                isMenuSelected(item) ? ' bg-white/[.1] border-l-[5px]' : ''
              }`"
              :style="`border-color: ${homeConfig.actionColor}`"
            >
              <q-avatar
                size="lg"
                :icon="item.icon"
                class="text-grey300"
                style="cursor: pointer"
              ></q-avatar>

              <q-menu
                v-model="item.show"
                @mouseleave="item.show = false"
                :offset="[-75, -50]"
              >
                <q-list
                  dense
                  :style="`background-color: ${homeConfig.menuColor}`"
                >
                  <q-item-label
                    header
                    class="block text-white font-black uppercase text-xs"
                    >{{
                      item.title.startsWith("$") ? $t(item.title) : item.title
                    }}</q-item-label
                  >
                  <q-item
                    v-for="(submenuItem, index) in item.submenu"
                    dense
                    :key="index.id"
                    class="p-0"
                  >
                    <router-link
                      :to="`/${submenuItem.key}`"
                      class="hover:opacity-100 text-[12px]"
                      :class="`${
                        isRouteSelected(submenuItem)
                          ? 'opacity-100 font-bold'
                          : 'opacity-60 font-medium'
                      }`"
                      :style="`color: ${
                        isRouteSelected(submenuItem)
                          ? `${homeConfig.actionColor}`
                          : 'white'
                      }`"
                    >
                      {{
                        submenuItem.title.startsWith("$")
                          ? $t(submenuItem.title)
                          : submenuItem.title
                      }}
                    </router-link>
                  </q-item>
                </q-list>
              </q-menu>
            </q-item>
          </template>
        </q-list>
      </q-scroll-area>
    </div>
  </div>
</template>

<script>
import { useMenuSidebarStore } from "@/store/menuStore";
import { useHomeConfig } from "@/store/homeConfigStore.js";
import MenuSkeletonComponentVue from "./skeleton/menuSkeletonComponent.vue";

export default {
  name: "MenuSidebarComponent",
  components: { MenuSkeletonComponentVue },
  data() {
    const store = useMenuSidebarStore();
    const homeConfig = useHomeConfig();
    return {
      store,
      overflow: false,
      homeConfig,
      open: true,
      submenuOpen: false,
      hostname: window.location.hostname,
      menuViews: [],
      getElement: "",
      getElementSubmenu: "",
      positionElements: [],
      menu_direction: true,
    };
  },
  async mounted() {
    await this.store.getMenu();
    this.defineMenu();
    // console.log(this.menuViews);
  },

  computed: {
    background() {
      return `bg-[${this.homeConfig.menuColor}]`;
    },

    status() {
      return this.store.status;
    },
    menu: {
      get() {
        return this.store.itemsMenu;
      },
      set(newMenu) {
        this.store.itemsMenu = newMenu;
      },
    },
  },
  methods: {
    isMenuSelected(item) {
      const currentPath = this.$route.path;
      return item.submenu.some((subItem) => currentPath.includes(subItem.key));
    },

    isRouteSelected(item) {
      return this.$route.path.includes(`/${item.key}`);
    },

    mouseover() {
      this.overflow = false;
    },
    mouseleave() {
      this.overflow = true;
    },
    defineMenu() {
      if (this.hostname.includes("ez.eztools")) {
        this.menuViews = [
          {
            id: 2,
            icon: "fas fa-tasks-alt",
            title: "Projetos",
            submenu: [
              { id: 1, title: "Boards", key: "agile/boards" },
              { id: 2, title: "Gestão de Projetos", key: "agile/projects" },
            ],
          },
        ];
      } else if (this.hostname.includes("localhost")) {
        this.menuViews = [
          {
            id: 2,
            icon: "fas fa-calendar",
            title: "Services",
            submenu: [
              { id: 2, title: "Boards", key: "agile/boards" },
              { id: 7, title: "Checklist Frota", key: "c/dv3/checklist" },
              { id: 3, title: "Email", key: "agile/email" },
              { id: 4, title: "Flow", key: "flow" },
              { id: 5, title: "Form Builder", key: "form-builder/create" },
              { id: 1, title: "Gestão de Projetos", key: "agile/projects" },
              { id: 6, title: "Monitor", key: "int-monitor" },
            ],
          },
          {
            id: 1,
            icon: "fas fa-window",
            title: "Views",
            submenu: [
              {
                id: 3,
                path: "views",
                title: "Kanban Dash",
                key: "views/dash/23",
              },
              {
                id: 3,
                path: "matriz 1",
                title: "Matriz Dash 1",
                key: "views/dash/117",
              },
              {
                id: 3,
                path: "matriz 2",
                title: "Matriz Dash 2",
                key: "views/dash/127",
              },
            ],
          },
        ];
      } else if (this.hostname.includes("dv3")) {
        this.menuViews = [
          {
            id: 1,
            icon: "fas fa-ballot-check",
            title: "Formulários",
            submenu: [
              { id: 2, title: "Checklist Frota", key: "c/dv3/checklist" },
            ],
          },
        ];
      } else {
        this.menuViews = [];
      }
    },

    calcPosOfBox(index) {
      this.getElement = this.$refs.ref_menu;

      this.getElement.map((element, idx) => {
        if (idx === index) {
          this.positionElements = this.getElement[idx].getBoundingClientRect();
        }
      });
      this.isInViewport(this.positionElements);
    },

    calcPosOfBoxSubmenu(index) {
      this.getElementSubmenu = this.$refs.ref_submenu;

      this.getElementSubmenu.map((element, idx) => {
        if (idx === index) {
          this.positionElements =
            this.getElementSubmenu[idx].getBoundingClientRect();
        }
      });
      this.isInViewport(this.positionElements);
    },

    isInViewport(element) {
      if (
        element.top >= 0 &&
        element.left >= 0 &&
        element.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        element.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.menu_direction = true;
      } else {
        this.menu_direction = false;
      }
    },
    changeStatus() {
      // this.$emit("changeWidthMain", this.open);
      this.open = !this.open;
      this.submenuOpen = false;
    },
    showMenu(item) {
      this.menu = this.menu.map((m) => {
        m["show"] = false;
        return m;
      });
      this.menuViews = this.menuViews.map((m) => {
        m["show"] = false;
        return m;
      });
      item.show = true;
    },
  },
};
</script>

<style>
.MenuHoverScrool::-webkit-scrollbar,
#MenuItems::-webkit-scrollbar {
  width: 3px;
}
.MenuHoverScrool::-webkit-scrollbar-track,
#MenuItems::-webkit-scrollbar-track {
  background: rgb(54, 54, 54);
  border-radius: 10px;
}
.MenuHoverScrool::-webkit-scrollbar-thumb,
#MenuItems::-webkit-scrollbar-thumb {
  background-color: rgb(128, 128, 128);
  border-radius: 20px;
}

#SubmenuHover {
  animation: fadeinout 0.3s linear forwards;
}

#MenuCloseHoverText::first-letter,
#MenuOpenHoverText::first-letter {
  text-transform: capitalize;
}

@keyframes fadeinout {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes stagger {
  from {
    opacity: 0;
    transform: translateX(-80px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
