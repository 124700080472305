<template>
  <aside
    :class="`block md:hidden bg-white absolute top-0 h-full overflow-y-hidden z-50 ${
      showMenuMobile ? 'w-full' : 'w-0'
    } transition-all motion-reduce:trnasition-none`"
  >
    <header class="flex justify-end mt-2 mb-3 mr-2">
      <q-btn
        round
        color="primary"
        icon="close"
        @click="store.showMenuMobile = false"
      />
    </header>
    <q-scroll-area style="height: calc(100% - 150px)">
      <main class="w-[90%] m-auto">
        <q-expansion-item
          v-for="(item, index) in menu"
          :key="index"
          group="somegroup"
        >
          <template v-slot:header>
            <div class="border-b border-grey200 flex w-full pb-2">
              <q-item-section avatar>
                <q-avatar color="teal" text-color="white" :icon="item.icon" />
              </q-item-section>

              <q-item-section
                class="capitalize text-grey700 text-md font-medium tracking-wider"
              >
                {{ item.title.startsWith("$") ? $t(item.title) : item.title }}
              </q-item-section>
            </div>
          </template>

          <q-card>
            <q-item
              clickable
              v-ripple
              v-for="(subitem, index) in item.submenu"
              :key="index"
              style="padding: 8px 50px !important"
            >
              <router-link
                :to="`/${subitem.key}`"
                class="opacity-60 hover:opacity-100 font-medium text-md tracking-widest"
                @click="store.showMenuMobile = false"
              >
                {{
                  subitem.title.startsWith("$")
                    ? $t(subitem.title)
                    : subitem.title
                }}
              </router-link>
            </q-item>
          </q-card>
        </q-expansion-item>

        <!-- *********************** MENU FIXO -->
        <q-expansion-item
          v-for="(item, index) in menuViews"
          :key="index"
          group="somegroup"
        >
          <template v-slot:header>
            <div class="border-b border-grey200 flex w-full pb-2">
              <q-item-section avatar>
                <q-avatar color="teal" text-color="white" :icon="item.icon" />
              </q-item-section>

              <q-item-section
                class="capitalize text-grey700 text-md font-medium tracking-wider"
              >
                {{ item.title.startsWith("$") ? $t(item.title) : item.title }}
              </q-item-section>
            </div>
          </template>

          <q-card>
            <q-item
              clickable
              v-ripple
              v-for="(subitem, index) in item.submenu"
              :key="index"
              style="padding: 8px 50px !important"
            >
              <router-link
                :to="`/${subitem.key}`"
                class="opacity-60 hover:opacity-100 font-medium text-md tracking-widest"
                @click="store.showMenuMobile = false"
              >
                {{
                  subitem.title.startsWith("$")
                    ? $t(subitem.title)
                    : subitem.title
                }}
              </router-link>
            </q-item>
          </q-card>
        </q-expansion-item>
      </main>
    </q-scroll-area>
    <footer class="absolute bottom-[4px] bg-white w-full">
      <q-item clickable v-ripple>
        <q-item-section avatar>
          <q-avatar
            :color="currentUserPhotoUrl ? 'white' : 'accent'"
            text-color="white"
          >
            <img v-if="currentUserPhotoUrl" :src="currentUserPhotoUrl" />
            <span v-else>{{ getInitialLetters(username) }}</span>
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label lines="1">{{ username }}</q-item-label>
          <q-item-label caption lines="2">
            {{ hostname.split(".")[0] }}
          </q-item-label>
        </q-item-section>

        <q-item-section side>
          <q-btn
            color="primary"
            icon="fas fa-power-off"
            round
            flat
            dense
            @click="logout"
          />
        </q-item-section>
      </q-item>
    </footer>
  </aside>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { useMenuSidebarStore } from "@/store/menuStore";
import { useLoginStore } from "@/store/loginStore";
import { getInitialLetters } from "@/utils/useUtils";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useMenuSidebarStore();
const loginStore = useLoginStore();
const menu = computed(() => store.itemsMenu);
const menuViews = ref([]);

onMounted(async () => {
  await store.getMenu();
  defineMenu();
});

const showMenuMobile = computed(() => store.showMenuMobile);

const hostname = ref(window.location.hostname);

const currentUserPhotoUrl = computed(() => {
  return loginStore.profileImage ? loginStore.profileImage : "";
});

const username = computed(() => {
  const storedUsername = localStorage.getItem("@vueweb:loggedUser");
  return storedUsername !== null && storedUsername !== "undefined"
    ? storedUsername
    : "";
});

const defineMenu = () => {
  if (hostname.value.includes("ez.eztools")) {
    menuViews.value = [
      {
        id: 2,
        icon: "fas fa-tasks-alt",
        title: "Projetos",
        submenu: [
          { id: 1, title: "Boards", key: "agile/boards" },
          { id: 2, title: "Gestão de Projetos", key: "agile/projects" },
        ],
      },
    ];
  } else if (hostname.value.includes("localhost")) {
    menuViews.value = [
      {
        id: 2,
        icon: "fas fa-calendar",
        title: "Services",
        submenu: [
          { id: 2, title: "Boards", key: "agile/boards" },
          { id: 7, title: "Checklist Frota", key: "c/dv3/checklist" },
          { id: 3, title: "Email", key: "agile/email" },
          { id: 4, title: "Flow", key: "flow" },
          { id: 5, title: "Form Builder", key: "form-builder/create" },
          { id: 1, title: "Gestão de Projetos", key: "agile/projects" },
          { id: 6, title: "Monitor", key: "int-monitor" },
        ],
      },
      {
        id: 1,
        icon: "fas fa-window",
        title: "Views",
        submenu: [
          {
            id: 3,
            path: "views",
            title: "Kanban Dash",
            key: "views/dash/23",
          },
          {
            id: 3,
            path: "matriz 1",
            title: "Matriz Dash 1",
            key: "views/dash/117",
          },
          {
            id: 3,
            path: "matriz 2",
            title: "Matriz Dash 2",
            key: "views/dash/127",
          },
        ],
      },
    ];
  } else if (hostname.value.includes("dv3")) {
    menuViews.value = [
      {
        id: 1,
        icon: "fas fa-ballot-check",
        title: "Formulários",
        submenu: [{ id: 2, title: "Checklist Frota", key: "c/dv3/checklist" }],
      },
    ];
  } else {
    menuViews.value = [];
  }
};

const logout = () => {
  localStorage.removeItem("@vueweb:accessToken");
  localStorage.removeItem("@vueweb:username");
  localStorage.removeItem("@vueweb:user");
  localStorage.removeItem("@vueweb:currentEnvironment");
  localStorage.removeItem("@vueweb:loggedUser");
  localStorage.removeItem("@vueweb:imageProfile");
  router.push("/login");
};
</script>
